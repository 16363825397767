<script>
export default {
  name: 'ModalConfirm',
  components: {
    Action: () => import('@/components/general/Action'),
    Modal: () => import('@/components/general/Modal')
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },

    hasMultipleParagraphs: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: null,
      required: false
    },

    zInd: {
      type: Number,
      default: null,
      required: false
    },

    hideCancel: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    confirm () {
      this.$emit('confirmAction')
    },

    cancel () {
      this.$emit('cancelAction')
    }
  }
}
</script>

<template>
  <Modal
    :active="active"
    :cancel="false"
    :z-ind="zInd"
    @close="cancel"
  >
    <div class="modal-confirm">
      <div
        v-if="title"
        class="modal-confirm-content"
      >
        <h3 class="modal-confirm-title">
          {{ title }}
        </h3>
      </div>

      <div
        v-if="description && !hasMultipleParagraphs"
        class="modal-confirm-description"
      >
        <p>{{ description }}</p>
      </div>

      <div
        v-if="description && hasMultipleParagraphs"
        class="modal-confirm-description"
      >
        <p
          v-html="description"
        />
      </div>

      <div v-if="!description">
        <slot />
      </div>

      <div class="modal-confirm-footer">
        <Action
          type="button"
          :text="$t('global:confirm')"
          flat
          class="btn-confirm"
          :dark="false"
          @click="confirm"
        />
        <Action
          v-if="!hideCancel"
          type="button"
          :text="$t('global:cancel')"
          flat
          :dark="false"
          @click="cancel"
        />
      </div>
    </div>
  </Modal>
</template>
